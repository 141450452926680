<template>
  <el-form-item
    :prop="name"
    v-show="!isHidden"
  >
      <el-input v-bind:value="value" type="text" v-on:input="$emit('input', $event)" :name="name" ></el-input>
    <slot></slot>
  </el-form-item>
</template>

<script>
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import mixin from '@/components/InterfaceEditor/components/mixins'

export default {
  name: 'geometry_field',
  mixins: [mixin, VisibleMixin],
  props: {
    value: {
      frozen: true
    },
    name: {
      type: String,
      description: 'attribute',
      options: {
        removeSpaces: true
      }
    }
  }
}
</script>

<style scoped>

</style>
